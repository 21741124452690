import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.expandOrCollapse();

    this.target = document.querySelector('#tb_qs_questions_container');
    this.observer = new MutationObserver(_mutations => {
      this.expandOrCollapse();
    });

    this.observer.observe(this.target, {childList: true});
  }

  onChange() {
    this.expandOrCollapse();
  }

  disconnect() {
    this.observer.disconnect();
  }

  // private

  expandOrCollapse() {
    const expandCheckboxIsChecked = document.getElementById('expandQuestionNodes').checked;
    // Get card bodies, both teacher and publishing space
    const questionEls = document.querySelectorAll(
      '#tb_qs_questions_container .t_qnt_container_compact .t_qnt_body, #pub_qs_question_node_trees_container .pub_qnt_container_compact .pub_qnt_body'
    );
    questionEls.forEach(questionEl => {
      if (expandCheckboxIsChecked) {
        questionEl.classList.remove("limit_qn_height");
        IbQuestionbank.Formulae.typeSet();
      } else {
        questionEl.classList.add("limit_qn_height");
      }
    });
  }
}
